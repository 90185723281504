<template>
  <div
    class="filter-advanced__wrapper"
    :class="{ 'is-hidden': isHidden }"
  >
    <div
      ref="scrollerRef"
      class="filter-advanced"
      @scroll="handleScroll"
    >
      <FormInputSelectField
        v-for="dropdown of dropdowns"
        :key="dropdown._uid"
        class="filter-advanced__select"
        :options="dropdown.options"
        :data="dropdown"
        :prefill-value="dropdown.prefillValue?.length > 0 ? dropdown.prefillValue : []"
        :is-full-screen="true"
        :is-hidden="dropdown.is_hidden"
        @emit-value="setFilterValues"
      />
    </div>

    <transition name="fade">
      <div v-if="!scrolledToEnd" class="filter-advanced__fade" />
    </transition>
  </div>
</template>

<script setup>

const emit = defineEmits(['set-availability', 'set-filter-advanced']);

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    dropdowns: {
        type: Array,
        default: () => [],
    },

    isHidden: {
        type: Boolean,
        default: false,
    },
});

/*
    Get preselections
*/
const getPreselections = (dropdown) => {
    if (dropdown.is_availability) {
        return 'Alle';
    }

    if (props.data.use_strapi_data && dropdown.collection) {
        const storyStore = useStoryStore();
        const relationalData = storyStore.getConnectedStrapiValue(dropdown.collection);
        if (relationalData?.length > 0) {
            return relationalData.map((item) => item.name);
        }
    }

    return dropdown.preselections?.map((item) => item.value) || [];
};

/*
    Initialize dropdowns
*/
const readyDropdowns = ref([]);

// Init dropdowns
watchEffect(() => {
    if (props.data.dropdowns && Array.isArray(props.data.dropdowns)) {
        props.data.dropdowns.forEach((dropdown) => {
            readyDropdowns.value.push({
                ...dropdown,
                field_key: dropdown.collection || 'availability',
                is_multi_select: !dropdown.is_availability,
                prefillValue: getPreselections(dropdown),
                is_hidden: dropdown.is_hidden,
            });
        });
    }
});

/*
    Filter values
*/
const selectedValues = ref({});
const availabilityValue = ref('all');

const emits = ref(0);

const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
};

const setFilterValues = (data) => {
    emits.value += 1;
    const isInitial = emits.value <= props.dropdowns?.length;

    if (data.key === 'availability') {
        availabilityValue.value = data.value;
        if (!isInitial) {
            emit('set-availability', {
                values: availabilityValue.value,
            });
        }
        return;
    }

    const newValues = {
        ...selectedValues.value,
        [data.key]: data.value,
    };

    if (!deepEqual(selectedValues.value, newValues)) {
        selectedValues.value = newValues;
        if (!isInitial) {
            emit('set-filter-advanced', {
                values: selectedValues.value,
            });
        }
    }
};

/*
    Mobile scroller fade
*/
const scrollerRef = ref(null);
const scrolledToEnd = ref(false);

const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollerRef.value;
    scrolledToEnd.value = scrollLeft + clientWidth >= scrollWidth;
};
</script>

<style lang="scss" scoped>
.filter-advanced__wrapper {
    @include grid-columns(1, var(--grid-columns));

    position: relative;
    z-index: 10;
    border-radius: 12px;
    margin-bottom: 30px;

    @include mobile {
        @include grid-full;

        position: relative;
        overflow: hidden;
        width: 100vw;
        border-radius: 0;
    }

    .shop-products-combined & {
        margin-bottom: 0;
    }

    &.is-hidden {
        display: none;
    }

}

.filter-advanced__fade {
    position: absolute;
    z-index: 98;
    top: 0;
    right: 0;
    display: none;
    width: 72px;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255, 0.9) 71.22%);

    @include mobile {
        display: inline-block;
    }
}

.filter-advanced {
    @include remove-scrollbars;
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 13px;

    --filter-columns: 4;
    --filter-columns-gap: 10px;

    @include tablet {
        --filter-columns: 3;
    }

    @include tablet-portrait {
        --filter-columns: 2;
    }

    @include mobile {
        flex-wrap: nowrap;
        overflow-x: scroll;
        white-space: nowrap;
    }
}

.filter-advanced__select {
    width: calc(100% / var(--filter-columns) - var(--filter-columns-gap));

    @include mobile {

        width: auto;
        min-width: 250px;
        flex: 1;

        &:first-child {
            margin-left: var(--page-padding);
        }

        &:last-child {
            margin-right: var(--page-padding);
        }

    }
}
</style>
